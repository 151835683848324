<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-col align="start" justify="start">
            <v-btn
            @click="()=>{
                this.$router.go(-1);
            }"
            class="mb-3"
            color="primary">
                <v-icon>
                    mdi-arrow-left-bold
                </v-icon>
            </v-btn>
        </v-col>    
        <ASuccessFour :api="this.api"/>
        <v-skeleton-loader
            v-if="api.isLoading"
            ref="skeleton"
            type="table">

        </v-skeleton-loader>
        <!-- <v-skeleton-loader v-if="api.isLoading" ref="skeleton" type="table">
        </v-skeleton-loader> -->
        <v-card class="pb-4"
            v-if=!api.isLoading>
            <v-toolbar class="white--text text-h5 mb-4" color="secondary">
                New Staff
            </v-toolbar>
            <div>
                <v-card class="ma-5 pa-2">
                    <v-row no-gutters>
                        <v-col>
                            <div class="px-2">
                                <div class="d-flex flex mt-4">
                                    <v-text-field label="Company" v-model="company.name" dense outlined disabled filled>

                                    </v-text-field>
                                </div>
                                <div class="d-flex flex-row">

                                    <div class="flex mr-2">
                                        <v-text-field v-model="users.user_fname" :rules="requiredRules" label="First Name" dense outlined>
    
                                        </v-text-field>
                                    </div>
                                    <div class="flex">
                                        <v-text-field v-model="users.user_lname" :rules="requiredRules" label="Last Name" dense outlined>
    
                                        </v-text-field>
                                    </div>
                                </div>
                                <div class="d-flex flex-row">
                                    <div class="flex mr-2">
                                        <v-text-field v-model="users.email" :rules="requiredRules" label="Email" dense outlined>
    
                                        </v-text-field>
                                    </div>
                                    <div class="flex">
                                        <v-text-field v-model="users.mobile" :rules="requiredRules" label="Mobile" dense outlined>
    
                                        </v-text-field>
                                    </div>

                                </div>
                                <!-- <div class="d-flex justify-start">
                                    <v-text-field v-model="users.password" :rules="requiredRules" label="password" dense outlined>

                                    </v-text-field>
                                </div> -->
                                <div
                                    v-if="this.$router.history.current.params.id!=33">
                                    <v-autocomplete dense outlined :rules="requiredRules" v-model="users.user_account_type" :items="AccountUserOptions" label="User Account Type">
                                    </v-autocomplete>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-autocomplete :items="RoleOptions" :rules="requiredRules" v-model="users.user_role" dense outlined label="Masterclass Role">

                                    </v-autocomplete>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-autocomplete :items="AAERoleOptions"
                                    item-text="text"
                                    item-value="value" v-model="users.aae_role" dense outlined label="AAE Role">
                                    </v-autocomplete>
                                </div>
                                <div
                                    class="d-flex justify-start">
                                    <v-autocomplete
                                        :items="VisionRoleOptions"
                                        item-text="text"
                                        item-value="value"
                                        v-model="users.vision_role"
                                        dense
                                        outlined
                                        label="Vision Role">

                                    </v-autocomplete>

                                </div>
                                <div class="d-flex flex-row">
                                    <div class="flex mr-2">
                                        <v-autocomplete @change="updateMainCategory" :rules="requiredRules" :items="MainCategoryOptions" clearable v-model="users.main_category" dense outlined label="Main Category">

                                        </v-autocomplete>
                                    </div>
                                    <div class="flex" v-if="users.main_category==='TaxPOD Client'">
                                        <v-autocomplete :items="TaxPODClientOptions" v-model="users.sub_category" dense outlined label="Sub category" >

                                        </v-autocomplete>
                                    </div>
                                    <div class="flex" v-if="users.main_category==='Internal Staff'">
                                        <v-autocomplete :items="InternalStaffOptions" v-model="users.sub_category" dense outlined label="Branch">

                                        </v-autocomplete>
                                    </div>
                                    <div class="flex" v-if="users.main_category===null || users.main_category==='SmartCFO Client'">
                                        <v-autocomplete  disabled :items="TaxPODClientOptions" v-model="users.sub_category" dense outlined label="Sub category" >

                                        </v-autocomplete>
                                    </div>
                                </div>
                                <!-- <v-row>
                                    <v-col>
                                        <ComponentCSField
                                            :fieldValue="users.cs_pic"
                                            @updateData="(data)=>{
                                                users.cs_pic = data;
                                            }"/>
                                    </v-col>
                                </v-row> -->
                                <v-row no-gutters>
                                    <v-col class="pb-0">
                                    <div v-if="checkIfCanAccessOnboarding" class="px-2">
                                        <ComponentDateModal label="Welcome Guide Sent ( Optional )" :data="users.welcome_guide_sent" @updateDateData="(data) => {
                                            users.welcome_guide_sent = data;
                                        }" />
                                    </div>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                    <div v-if="checkIfCanAccessOnboarding" class="px-2">
                                        <ComponentDateModal label="Welcome WA Sent ( Optional )" :data="users.welcome_wa_sent" @updateDateData="(data) => {
                                            users.welcome_wa_sent = data;
                                        }" />
                                    </div>
                                    </v-col>
                                </v-row>
                                <!-- <div class="d-flex flex-row">
                                    <div>
                                        <span class="text-h7 font-weight-bold m">
                                            Status :
                                        </span>
                                    </div>
                                </div>
                                <div class="d-flex justify-start">
                                    <v-switch v-model="users.isEnable">
                                        <template v-slot:label>
                                            <v-chip color="green" class="text-h7 white--text" v-if="users.isEnable">
                                                Active
                                            </v-chip>
                                            <v-chip color="red" class="text-h7 white--text" v-else>
                                                Deactive
                                            </v-chip>
                                        </template>
                                    </v-switch>
                                </div> -->
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
            <div class="d-flex justify-end pt-5 px-5">

                <v-btn color="primary" plain @click="()=>{
                    $router.go(-1)
                }">
                    Cancel
                </v-btn>
                <v-btn :loading="api.isLoading" color="primary" @click="validateInput">
                    Confirm
                </v-btn>
            </div>
            <AError :api="this.api" class="mt-3 mx-4"/>

            <div  v-if="nameExisted" class="d-flex justify-end px-4 pb-4">
            </div>
        </v-card >
    </v-container>
</template>

<script>
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
import ComponentDateModal from '../../components/date/ComponentDateModal.vue';
// import ComponentCSField from '../../components/company/field/ComponentCSField.vue';

export default{
    data: () => ({
        requiredAsterik:'<span style="color:red"> * </span>',
        requiredRules:[v=>!!v || 'This field is required'],
        isPending: false,
        companies: {

        },
        users: {
            isEnable :true,
            welcome_wa_sent:null,
            welcome_guide_sent:null,
            aae_role:'disabled',
            vision_role:'disabled',
            cs_pic:{
                'username':null,
                'id':null,
            },
        },
        company:null,
        nameExisted:false,
        existedCompanyId:null,
        isVerified: false,
        isVerifiedCustomValue: 'Verified',
        isNotVerifiedCustomValue: 'Not Verified',
        isEnable: false,
        isEnableCustomValue: 'Enable',
        isNotEnableCustomValue: 'Disable',
        AccountCompanyOptions:["First","Second","Third"],
        AccountSubCompanyOptions:["Renewal","First Subscription"],
        AAERoleOptions:[
            {
               text:'No access to AAE',
               value:'disabled'
            },
            {
                text:'Client',
                value:'client'
            },
            {
                text:'Consultant',
                value:'consultant',
            },
            {
                text:'Tax Expert',
                value:'tax_expert',
            },
            {
                text:'Admin',
                value:'admin'
            },
            {
                text:'Manager',
                value:'manager'
            }
         ],
        RoleOptions: ["User", 'Admin', "Instructor","Manager"],
        AccountUserOptions:['primary',"secondary"],
        AAERoleSelected: "Client",
        MainCategoryOptions : ["SmartCFO Client","TaxPOD Client","Internal Staff"],
        TaxPODClientOptions : ["Normal","Private Client","Non-Private Client","Accountant"],
        InternalStaffOptions:["HQ & COE","CM","Penang","Johor","Klang","Boss Boleh"],
        api: {
            isLoading: false,
            isError: false,
            error: null,
            url: null,
            isSuccesful: false,
            success: null,
        },
        VisionRoleOptions:[
            {
               text:'No access to vision',
               value:'disabled'
            },
            {
               text:'Salesperson',
               value:'salesperson'
            },
            {
               text:'Customer service',
               value:'customer_service'
            },
            {
               text:'Operation',
               value:'operation'
            },
            {
               text:'Admin',
               value:'vision_admin',
            },
            {
               text:'Sales team lead',
               value:'sales_team_lead',
            },
            {
               text:'Sales manager',
               value:'sales_manager'
            },
         ],
    }),
    computed: {
        checkIfCanAccessOnboarding() {
            if (this.$store.getters.getFeatures.onboarding.status == 1 || (this.$store.getters.getFeatures.onboarding.beta == 1 && this.$store.getters.getRole.includes('developer'))) {
            return true;
         }
         return false;
        },
        ComputeCompanyIsVerified(verified_at) {
            return (verified_at != null) ? true : false;
        },
        MappingSwitchIsVerified: {
            get(tempIsVerified) {
                return tempIsVerified == true ? this.isVerifiedCustomValue : this.isNotVerifiedCustomValue;
            },
            set(value) {
                this.isVerified = value === this.isVerifiedCustomValue;
            }
        },
        MappingSwitchIsEnable: {
            get() {
                return this.isEnable ? this.isEnableCustomValue : this.isNotEnableCustomValue;
            },
            set(value) {
                this.isEnable = value === this.isEnableCustomValue;
            },
        }
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="checkRedundantCompany"){
                if(resp.status=="fail"){
                    this.nameExisted =true;
                    this.existedCompanyId = resp.data.id;
                }
                if(resp.status=="success"){

                    this.createNewCompany();
                    return 1;
                }
            }
            if(resp.class==="getCompany"){
                this.company =resp.data;
            }
            if(resp.class=="store"){
                if(resp.status=="fail"){
                    this.api.isError = true;
                    this.api.error = resp.data;
                    this.api.isLoading = false;
                    return 1;
                }
                this.api.isSuccesful = true;
                this.api.success = "Succesfully Add New User";
            }

            this.api.isLoading = false;
            this.api.isError = false;
        };
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
        if(this.$router.history.current.params.id == 33) {
            this.users.main_category = "Internal Staff";
            this.users.user_role = "User";
        }
            let fetchCompanyApi = this.fetchCompany();
            this.$api.fetch(fetchCompanyApi);
            // let fetchCompaniesDetailApi = this.fetchCompaniesDetail();
            // this.$api.fetch(fetchCompaniesDetailApi);
        },
        updateMainCategory(){
            if(this.users.main_category==null || this.users.main_category==="SmartCFO Client"){
                this.users.sub_category = null;
            }
        },
        fetchCompany(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/companies/"+this.$router.history.current.params.id;
            return tempApi;
        },
        validateInput() {
            if(this.users.email==null){
                this.api.isError = true;
                this.api.error = "Please Fill in the user email field"
                return 1;
            }
            if(this.users.user_fname==null){
                this.api.isError = true;
                this.api.error = "Please Fill in the user first name"
                return 1;
            }
            if(this.users.user_lname==null){
                this.api.isError = true;
                this.api.error = "Please Fill in the user last name"
                return 1;
            }
            if(this.users.user_account_type==null && this.$router.history.current.params.id!=33){
                this.api.isError = true;
                this.api.error = "Please Fill in the user account type"
                return 1;
            }
            if(this.users.user_role==null){
                this.api.isError = true;
                this.api.error = "Please fill in the user role";
                return 1;
            }
            // if(this.users.password == null){
            //     this.api.isError = true;
            //     this.api.error = "Please fill in the password field"
            //     return 1;
            // }
            
            this.isPending = true;
        },
        checkRedundantCompanyName(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/company/name";
            let formData = new FormData;
            formData.append('company_name',this.companies.company_name);
            tempApi.params = formData;
            return tempApi;
        },
        createNewCompany(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/company";
            let formData = new FormData;
            formData.append('company_name',this.companies.company_name);
            formData.append('company_address',this.companies.company_address);
            formData.append('company_ssm',this.companies.company_ssm);
            formData.append('company_coupon',this.companies.company_coupon);
            formData.append('company_industry',this.companies.company_industry);
            formData.append('company_acc_category',this.companies.account_type);
            if(this.companies.account_sub_type!=null){

                formData.append('company_acc_sub_category',this.companies.account_sub_type);
            }

            formData.append('user_fname',this.users.user_fname);
            formData.append('user_lname',this.users.user_lname);
            formData.append('email',this.users.email);
            formData.append('mobile',this.users.mobile);
            formData.append('main_category',this.users.main_category);
            formData.append('sub_category',this.users.sub_category);
            // formData.append('password',this.users.password);
            formData.append('user_role',this.users.user_role);
            formData.append('aae_role',this.users.aae_role);
            formData.append('user_acc_category',this.users.user_account_type);

            tempApi.params = formData;

            this.$api.fetch(tempApi);

        },
        createNewUser(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/users/client/company/"+this.$router.history.current.params.id;
            
            let formData = new FormData;
            formData.append('user_fname',this.users.user_fname);
            formData.append('user_lname',this.users.user_lname);
            formData.append('user_email',this.users.email);
            formData.append('user_mobile',this.users.mobile);
            formData.append('user_main_category',(this.users.main_category!='undefined')?this.users.main_category:null);
            formData.append('user_sub_category',this.users.sub_category);
            // formData.append('user_password',this.users.password);
            formData.append('user_role',this.users.user_role);
            formData.append('user_aae_role',this.users.aae_role);
            formData.append('user_acc_category',this.users.user_account_type);
            formData.append('user_status',this.users.isEnable);
            formData.append('user_vision_role',this.users.vision_role);
            if(this.users.cs_pic.id!=null) {
                formData.append('cs_pic',this.users.cs_pic.id);
            }
            if(this.users.welcome_guide_sent!=null) {
                formData.append('welcome_guide_sent',this.users.welcome_guide_sent);
            }
            if(this.users.welcome_wa_sent!=null) {
                formData.append('welcome_wa_sent',this.users.welcome_wa_sent);
            }
          

            tempApi.params = formData;
            return tempApi;
        },
        submit(){
            this.isPending = false;
            let createNewUserApi = this.createNewUser();
            this.$api.fetch(createNewUserApi);
            // this.isPending = false;
            // let formData = new FormData;
            // let tempApi = this.$_.clone(this.api);
            // tempApi.method = "POST";
            // tempApi.url = process.env.VUE_APP_SERVER_API+"/company/full/client/id/"+this.$router.history.current.params.id;
            // formData.append("company_name",this.companies.company_name);
            // formData.append("company_address",this.companies.company_address);
            // formData.append("company_coupon",this.companies.company_coupon);
            // formData.append('company_ssm',this.companies.company_ssm);
            // formData.append('company_industry',this.companies.company_industry);
            // formData.append('company_branch',this.companies.company_branch);
            // formData.append('subscription_type',this.companies.company_subscription_type);
            // formData.append('company_coupon',this.companies.company_coupon);
            // formData.append('causer_id',this.$store.getters.getUserId);

            // this.users.forEach(user => {
            //     let tempUser =
            //     {
            //         "user_id": user.user_id,
            //         "user_lname": user.user_lname,
            //         "user_fname": user.user_name,
            //         "user_email": user.user_email,
            //         "user_mobile": user.user_mobile,
            //         "user_role":user.user_role,
            //         "user_branch": user.user_branch,
            //         "user_verified_at": this.isVerified[this.users.indexOf(user)] ,
            //         "user_status":this.isEnable[this.users.indexOf(user)],
            //         "subscription_type": user.user_subscription_type,
            //     }
            //     formData.append('users[]',JSON.stringify(tempUser));
            // });

            // tempApi.params = formData;

            // this.$api.fetch(tempApi);

        },
        cancelSubmit(){
            this.isPending = false;
        },  
    },
    components: { AConfirmation,ASuccessFour,ComponentDateModal}
}
</script>